<template>
  <div id="checkOrder">
    <el-dialog
      :title="checkOrderFormTitle"
      width="680px"
      :visible.sync="checkOrderDialogVisible"
      :close-on-click-modal="false"
      @close="checkOrderDialogClose"
    >
      <el-form
        ref="checkOrderFormRef"
        :model="checkOrderForm"
        :rules="checkOrderFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="checkOrderForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格" prop="spec">
              <el-input v-model="checkOrderForm.spec" placeholder="请输入规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="batchNo">
              <el-input v-model="checkOrderForm.batchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="代表量" prop="amount">
              <el-input v-model="checkOrderForm.amount" placeholder="请输入代表量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="请验部门" prop="dept">
              <el-input v-model="checkOrderForm.dept" placeholder="请输入请验部门" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="请验人" prop="checker">
              <el-input v-model="checkOrderForm.checker" placeholder="请输入请验人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="请验日期" prop="checkDate">
              <el-date-picker v-model="checkOrderForm.checkDate" placeholder="请选择请验日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="接检人" prop="picker">
              <el-input v-model="checkOrderForm.picker" placeholder="请输入接检人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="接检日期" prop="inspectionDate">
              <el-date-picker v-model="checkOrderForm.inspectionDate" placeholder="请选择接检日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="checkOrderForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="checkOrderDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="checkOrderFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.productName" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="checkOrderPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="amount" label="代表量" />
      <el-table-column prop="dept" label="请验部门" />
      <el-table-column prop="checker" label="请验人" />
      <el-table-column label="请验日期">
        <template slot-scope="scope">
          <span v-if="scope.row.checkDate">{{ scope.row.checkDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="picker" label="接检人" />
      <el-table-column label="接检日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate">{{ scope.row.inspectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="checkOrderPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addCheckOrder, deleteCheckOrder, updateCheckOrder, selectCheckOrderInfo, selectCheckOrderList } from '@/api/universal/checkOrder'

export default {
  data () {
    return {
      checkOrderDialogVisible: false,
      checkOrderFormTitle: '',
      checkOrderForm: {
        id: '',
        productName: '',
        spec: '',
        batchNo: '',
        amount: '',
        dept: '',
        checker: '',
        checkDate: '',
        picker: '',
        inspectionDate: '',
        remarks: ''
      },
      checkOrderFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      checkOrderPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      }
    }
  },
  created () {
    selectCheckOrderList(this.searchForm).then(res => {
      this.checkOrderPage = res
    })
  },
  methods: {
    checkOrderDialogClose () {
      this.$refs.checkOrderFormRef.resetFields()
    },
    checkOrderFormSubmit () {
      if (this.checkOrderFormTitle === '请验单详情') {
        this.checkOrderDialogVisible = false
        return
      }
      this.$refs.checkOrderFormRef.validate(async valid => {
        if (valid) {
          if (this.checkOrderFormTitle === '新增请验单') {
            await addCheckOrder(this.checkOrderForm)
          } else if (this.checkOrderFormTitle === '修改请验单') {
            await updateCheckOrder(this.checkOrderForm)
          }
          this.checkOrderPage = await selectCheckOrderList(this.searchForm)
          this.checkOrderDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.checkOrderFormTitle = '新增请验单'
      this.checkOrderDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteCheckOrder(row.id)
        if (this.checkOrderPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.checkOrderPage = await selectCheckOrderList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.checkOrderFormTitle = '修改请验单'
      this.checkOrderDialogVisible = true
      this.selectCheckOrderInfoById(row.id)
    },
    handleInfo (index, row) {
      this.checkOrderFormTitle = '请验单详情'
      this.checkOrderDialogVisible = true
      this.selectCheckOrderInfoById(row.id)
    },
    selectCheckOrderInfoById (id) {
      selectCheckOrderInfo(id).then(res => {
        this.checkOrderForm.id = res.id
        this.checkOrderForm.productName = res.productName
        this.checkOrderForm.spec = res.spec
        this.checkOrderForm.batchNo = res.batchNo
        this.checkOrderForm.amount = res.amount
        this.checkOrderForm.dept = res.dept
        this.checkOrderForm.checker = res.checker
        this.checkOrderForm.checkDate = res.checkDate
        this.checkOrderForm.picker = res.picker
        this.checkOrderForm.inspectionDate = res.inspectionDate
        this.checkOrderForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectCheckOrderList(this.searchForm).then(res => {
        this.checkOrderPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectCheckOrderList(this.searchForm).then(res => {
        this.checkOrderPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectCheckOrderList(this.searchForm).then(res => {
        this.checkOrderPage = res
      })
    }
  }
}
</script>

<style>
</style>
